import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import ProjectPage from "./components/ProjectPage";
import "./App.css";
import ProjectDetail from "./components/ProjectDetail";
import { projectData } from "./data/projectData";
import MinecraftHome from "./components/minecraft/minecraft_home";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/minecraft" element={<MinecraftHome />} />
          <Route
            path="/projects/minecraft_mods/hotbath"
            element={<ProjectDetail project={projectData["hotbath"]} />}
          />
          <Route
            path="/projects/games/touhou_project_2d"
            element={
              <ProjectDetail project={projectData["touhou_project_2d"]} />
            }
          />
          <Route
            path="/projects/minecraft_mods/leetcraft"
            element={<ProjectDetail project={projectData["leetcraft"]} />}
          />
          <Route
            path="/projects/practical_tools/gesture_unlock"
            element={<ProjectDetail project={projectData["gesture_unlock"]} />}
          />
          <Route
            path="/projects/web_projects/crab_eats"
            element={<ProjectDetail project={projectData["crab_eats"]} />}
          />
          <Route
            path="/projects/web_projects/crab_stay"
            element={<ProjectDetail project={projectData["crab_stay"]} />}
          />
          <Route
            path="/projects/web_projects/nft_browser"
            element={<ProjectDetail project={projectData["nft_browser"]} />}
          />
          <Route
            path="/projects/practical_tools/crab_chatbot"
            element={<ProjectDetail project={projectData["crab_chatbot"]} />}
          />
          <Route
            path="/projects/web_projects/crab_online_music"
            element={
              <ProjectDetail project={projectData["crab_online_music"]} />
            }
          />
          <Route
            path="/projects/web_projects/crab_melodies"
            element={<ProjectDetail project={projectData["crab_melodies"]} />}
          />
          <Route
            path="/projects/web_projects/crab_online_music"
            element={
              <ProjectDetail project={projectData["crab_online_music"]} />
            }
          />
          <Route
            path="/projects/practical_tools/dicom_image_3d_builder"
            element={
              <ProjectDetail project={projectData["dicom_image_3d_builder"]} />
            }
          />
        </Routes>
      </Router>
    );
  }
}

export default App;
