export const projectData = {
  hotbath: {
    id: 1,
    title: "Hot Bath",
    background_theme: "MinecraftMod",
    tags: ["Minecraft Mods", "Game"],
    outline: "Minecraft Mod",
    description:
      "Dive into Minecraft like never before with the customised hot bath mod. Immerse yourself in a unique bathing experience that adds a relaxing touch to your gaming journey.",
    myRole:
      "All aspects of this project except for texture, including the idea, design, implementation, testing, and deployment.",
    startDate: "2022-01-01",
    endDate: "present",
    techStack: ["Java", "Gradle", "Minecraft Forge"],
    githubLink: "https://github.com/crabsatellite/hotBath",
    downloadLink: "https://www.curseforge.com/minecraft/mc-mods/hotbath",
    display_img_list: [
      `${process.env.PUBLIC_URL}/images/project_images/hotbath/hotbath_1.png`,
      `${process.env.PUBLIC_URL}/images/project_images/hotbath/hotbath_2.png`,
    ],
    href: "projects/minecraft_mods/hotbath",
    imgSrc: "images/img-hotbath.png",
    isVisible: true,
    status: "Done",
  },
  touhou_project_2d: {
    id: 2,
    title: "Touhou Project 2D Game",
    background_theme: "Game",
    tags: ["Game"],
    description:
      "Experience a bullet game extravaganza with our Touhou Project 2D Game. Enjoy the thrill of customising your own stage and game mode for an engaging and personalised gameplay.",
    myRole:
      "All aspects of this project except for texture, including the idea, design, implementation, testing, and deployment.",
    startDate: "2022-10-01",
    endDate: "2022-11-31",
    techStack: ["Java", "Gradle"],
    githubLink: "https://github.com/crabsatellite/touhouAdventure",
    demoVideoLink: `https://personalwebpage-videos.s3.us-east-2.amazonaws.com/Touhou Project 2D Game_demo.mp4`,
    display_img_list: [
      `${process.env.PUBLIC_URL}/images/project_images/touhou_project_2d/touhou_project_2d_1.png`,
      `${process.env.PUBLIC_URL}/images/project_images/touhou_project_2d/touhou_project_2d_2.png`,
    ],
    href: "projects/games/touhou_project_2d",
    imgSrc: "images/img-touhou_project_2d.png",
    isVisible: true,
    status: "Done",
  },
  leetcraft: {
    id: 3,
    title: "LeetCraft",
    background_theme: "MinecraftMod",
    tags: ["Minecraft Mods", "Game"],
    description:
      "Blend coding with gaming using the LeetCraft mod for Minecraft. Solve the LeetCode daily challenge right within the Minecraft environment and run code that saves results to a file.",
    myRole:
      "All aspects of this project except for texture, including the idea, design, implementation, testing, and deployment.",
    startDate: "2023-04-19",
    endDate: "N/A (In Progress)",
    techStack: ["Java", "Gradle", "Minecraft Forge"],
    githubLink: "https://github.com/crabsatellite/LeetCraft",
    href: "projects/minecraft_mods/leetcraft",
    imgSrc: "images/img-leetcraft.png",
    isVisible: false,
    status: "In progress",
  },
  gesture_unlock: {
    id: 4,
    title: "Gesture Unlock",
    background_theme: "EmbeddedSystem",
    tags: ["Embedded System", "Practical Tools"],
    description:
      "Step into the future of security with our gesture unlock system. Implemented on an STM32F board and based on the gyroscope and DTW algorithm, this system brings biometrics to your fingertips.",
    myRole:
      "All aspects of this project except for texture, including the software design, hardware integration, implementation, testing, and deployment.",
    startDate: "2023-04-26",
    endDate: "2023-05-19",
    techStack: ["C", "STM32F", "PlatformIO"],
    githubLink: "https://github.com/crabsatellite/Gesture_Unlock",
    href: "projects/practical_tools/gesture_unlock",
    imgSrc: "images/img-gesture_unlock.jpg",
    isVisible: true,
    status: "Done",
  },
  crab_eats: {
    id: 5,
    title: "Crab Eats",
    background_theme: "Website",
    tags: ["Website"],
    outline: "Website, Online Food Ordering Service",
    description:
      "Enjoy a seamless food ordering experience with Crab Eats. Our online ordering system demo lets you sign up, log in, and order food with just a few clicks.",
    myRole:
      "All aspects of this project, including the frontend, backend, UI/UX design, database design, and deployment.",
    startDate: "2023-01-20",
    endDate: "2023-05-05",
    techStack: ["React", "Java", "Spring", "MySQL"],
    githubFrontendLink: "https://github.com/crabsatellite/crabEats_frontend",
    githubBackendLink: "https://github.com/crabsatellite/crabEats_backend",
    // demoLink: "http://3.138.35.43/projects/web_projects/crab_eats/demo",
    display_img_list: [
      `${process.env.PUBLIC_URL}/images/project_images/crab_eats/crab_eats_1.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_eats/crab_eats_2.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_eats/crab_eats_3.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_eats/crab_eats_4.png`,
    ],
    href: "/projects/web_projects/crab_eats",
    imgSrc: "images/img-crab_eats.png",
    isVisible: true,
    status: "Done",
  },

  crab_stay: {
    id: 6,
    title: "Crab Stay",
    background_theme: "Website",
    tags: ["Website"],
    outline: "Website, Online Stays Booking Service",
    description:
      "Experience the convenience of booking accommodations with Crab Stay. As a demo booking system, users can sign in as a guest to book a stay or as a host to manage and upload your accommodations.",
    myRole:
      "All aspects of this project, including the frontend, backend, UI/UX design, database design, and deployment.",
    startDate: "2023-02-21",
    endDate: "2023-03-24",
    techStack: ["React", "Java", "Spring Boot", "MySQL", "Elasticsearch"],
    githubFrontendLink: "https://github.com/crabsatellite/crabStay_frontend",
    // githubBackendLink: "https://github.com/crabsatellite/crabStay_backend",
    display_img_list: [
      `${process.env.PUBLIC_URL}/images/project_images/crab_stay/crab_stay_1.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_stay/crab_stay_2.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_stay/crab_stay_3.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_stay/crab_stay_4.png`,
    ],
    href: "projects/web_projects/crab_stay",
    imgSrc: "images/img-crab_staying.jpg",
    isVisible: true,
    status: "Done",
  },
  nft_browser: {
    id: 7,
    title: "NFT Browser",
    background_theme: "Website",
    tags: ["Website"],
    description:
      "Discover the world of NFTs with our browser that utilises APIs provided by moralis.io. This tool helps you easily search and browse NFTs, bringing the digital art world closer to you.",
    myRole:
      "All aspects of this project, including the frontend, UI/UX design, and deployment.",
    startDate: "2023-03-01",
    endDate: "2023-03-31",
    techStack: ["React", "Moralis API"],
    githubLink: "https://github.com/crabsatellite/nftbrowser",
    //demoLink: "https://master.d3lcrvsygn5gex.amplifyapp.com/",
    display_img_list: [
      `${process.env.PUBLIC_URL}/images/project_images/nft_browser/nft_browser_1.png`,
      `${process.env.PUBLIC_URL}/images/project_images/nft_browser/nft_browser_2.png`,
      `${process.env.PUBLIC_URL}/images/project_images/nft_browser/nft_browser_3.png`,
      `${process.env.PUBLIC_URL}/images/project_images/nft_browser/nft_browser_4.png`,
      `${process.env.PUBLIC_URL}/images/project_images/nft_browser/nft_browser_5.png`,
    ],
    href: "projects/web_projects/nft_browser",
    imgSrc: "images/img-nft_browser.jpg",
    isVisible: true,
    status: "Done",
  },
  crab_chatbot: {
    id: 8,
    title: "Crab Chatbot",
    background_theme: "AI",
    tags: ["AI service", "Practical Tools"],
    outline: "AI customer service",
    description:
      "<p>A sophisticated customer support system based on email communication, powered by Python and the ChatGPT API. The system incorporates a DynamoDB database to store user conversation history, enabling context understanding. \
      The use of Prompt Engineering techniques provides the ChatGPT with pre-designed personalities to enhance its role-playing abilities.</p>\
      <p><strong>This project has been commercially deployed by <a href='http://www.kangkeer.com.cn/en/'>Shanghai Kangkeer Compressor Co., Ltd. (KKR)</a>, hence the source code is not publicly available.</p></strong>",
    myRole:
      "Responsible for all aspects of this project, including developing the main logic with Python, integrating the ChatGPT API, and managing the DynamoDB database. \
      Also designed and implemented the Prompt Engineering techniques to provide the ChatGPT with a unique role-playing personality.",
    startDate: "2023-02-01",
    endDate: "2023-02-28",
    techStack: ["Python", "ChatGPT API", "DynamoDB", "Prompt Engineering"],
    href: "projects/practical_tools/crab_chatbot",
    imgSrc: "images/img-crab_chatbot.png",
    isVisible: true,
    status: "Done",
  },
  crab_melodies: {
    id: 8,
    title: "Crab Melodies",
    background_theme: "AndroidApp",
    tags: ["Android App", "Practical Tools"],
    outline: "Android App, MVVM Music Player",
    description:
      "CrabMelodies is a Kotlin-based music streaming app leveraging Android Jetpack Library, Hilt Dependency Injection, and Google Exoplayer for global music playback.",
    myRole:
      "As the primary developer, I handled the full-stack development, UI/UX design, and managed the app's deployment.",
    startDate: "2023-06-03",
    endDate: "2023-06-12",
    techStack: [
      "Kotlin",
      "Android Jetpack",
      "Hilt",
      "Retrofit",
      "Room Database",
      "Google Exoplayer",
    ],
    githubLink: "https://github.com/crabsatellite/crab_melodies_android_app",
    display_img_list: [
      `${process.env.PUBLIC_URL}/images/project_images/crab_melodies/crab_melodies_1.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_melodies/crab_melodies_2.png`,
      `${process.env.PUBLIC_URL}/images/project_images/crab_melodies/crab_melodies_3.png`,
    ],
    demoVideoLink:
      "https://personalwebpage-videos.s3.us-east-2.amazonaws.com/Crab Melodies_demo.mp4",
    href: "/projects/web_projects/crab_melodies",
    imgSrc: "images/img-crab_music.jpg",
    isVisible: true,
    status: "Done",
  },
  crab_online_music: {
    id: 9,
    title: "Crab Online Music",
    background_theme: "Website",
    tags: ["Website"],
    outline: "Website, Online Music Player, Distributed System",
    description:
      "<p>Crab Online Music is a music streaming website built with Vue and Node.js. It features a music player with a custom UI, a music recommendation system. </p>\
      <p>This project used <strong>Distributed Architecture</strong> to handle the high volume of concurrent requests. \
     <strong>Microservices</strong> were implemented to handle different tasks, including user authentication, music streaming, etc. The microservices were deployed on AWS EC2 instances and were connected via a <strong>Message Queue</strong> (RabbitMQ).</p>",
    myRole:
      "As the primary developer, I handled the full-stack development, UI/UX design, and managed the app's deployment.",
    startDate: "2022-11-04",
    endDate: "N/A (Fixing Bugs)",
    techStack: [
      "Vue",
      "Node.js",
      "Express",
      "RabbitMQ",
      "AWS EC2",
      "AWS S3",
      "AWS RDS",
    ],
    href: "projects/web_projects/crab_online_music",
    imgSrc: "images/img-crab_online_music.png",
    isVisible: false,
    status: "In Progress",
  },
  // Autonomous Package Delivery With Visual Map Tracking
  // autonomous_package_delivery_service: {
  //   id: 10,
  //   title: "Autonomous Package Delivery Service",
  //   background_theme: "Website",
  //   tags: ["Website"],
  //   outline: "Website, Autonomous Package Delivery Service",
  //   description:
  //     "This is a web platform which aims at providing services to pick-up and deliver package autonomously from locations \
  //     designated by users. It mainly includes a user-friendly workflows to guide users for completing the package delivery steps and a visual map for tracking the location of package in real time. ",
  //   myRole:
  //     "As the primary developer, I handled the full-stack development, UI/UX design, and managed the app's deployment.",
  //   startDate: "2022-10-20",
  //   endDate: "N/A (Fixing Bugs)",
  //   techStack: ["Vue", "Node.js", "Express", "AWS EC2", "AWS S3", "AWS RDS"],
  //   href: "projects/web_projects/autonomous_package_delivery_service",
  //   imgSrc: "images/img-autonomous_package_delivery_service.png",
  //   isVisible: true,
  //   status: "Done",
  // },
  dicom_image_3d_builder: {
    id: 11,
    title: "DICOM Image 3D Builder",
    background_theme: "EmbeddedSystem",
    tags: ["Medical Software", "Practical Tools"],
    outline: "Application, DICOM Image 3D Builder",
    description:
      "This is a desktop application which aims at building 3D models from DICOM images. \
      It incorporates vtk library to render 3D models and provides a user-friendly interface \
      to allow users to interact with the 3D models.",
    myRole:
      "As the primary developer, I handled the full-stack development, \
      integration of vtk library, UI/UX design, and managed the app's deployment.",
    startDate: "2023-11-08",
    endDate: "2023-11-24",
    techStack: ["C++", "Qt", "VTK", "ITK", "CMake", "Visual Studio"],
    href: "projects/practical_tools/dicom_image_3d_builder",
    imgSrc: "images/img-dicom_image_3d_builder.jpg",
    githubLink: "https://github.com/crabsatellite/DICOMImage3DBuilder",
    demoVideoLink:
      "https://personalwebpage-videos.s3.us-east-2.amazonaws.com/DICOMImage3DBuilder_demo.mp4",
    downloadLink:
      "https://github.com/crabsatellite/DICOMImage3DBuilder/releases/tag/exe-file-example",

    isVisible: true,
    status: "Done",
  },
};
