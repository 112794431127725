import React from "react";
import { Layout, Typography, Spin, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Live2DMascotLoader from "../live2d_mascot_loader";

const { Content } = Layout;
const { Title } = Typography;

const MinecraftHome = () => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Content style={{ textAlign: "center" }}>
        <Button
          type="primary"
          icon={<ArrowLeftOutlined />}
          style={{ position: "absolute", top: 20, left: 20 }}
          href="/"
        >
          Back
        </Button>
        <Title level={2} style={{ marginTop: 60 }}>
          Minecraft Home Page (Under Construction)
        </Title>
        <Live2DMascotLoader />
      </Content>
    </Layout>
  );
};

export default MinecraftHome;
