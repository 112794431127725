import React, { useState, useEffect } from "react";
import "../../css/home/github_snake.css";

const GithubSnake = () => {
  const [imageError, setImageError] = useState(false);
  const [maxWidth, setMaxWidth] = useState("none"); // 初始值为"none"

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        // 当宽度大于768px时，设置最大宽度为视口宽度的2/3
        setMaxWidth(`${(window.innerWidth * 1) / 3}px`);
      } else {
        // 当宽度小于等于768px时，不设置最大宽度
        setMaxWidth("none");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    // 组件卸载时清除监听器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageError = () => {
    setImageError(true);
  };

  if (imageError) {
    return null; // 图片加载失败时返回 null，即不渲染任何内容
  }

  return (
    <div>
      <section className="colorlib-about" data-section="github_snake">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-12">
              <div
                className="row row-bottom-padded-sm animate-box"
                data-animate-effect="fadeInLeft"
              >
                <div className="col-md-12">
                  <div
                    className="about-desc animate-box"
                    style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                  >
                    <span className="heading-meta">Code Commitments</span>
                    <h2 className="colorlib-heading">
                      Tracking Daily Code Progress
                    </h2>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src="https://raw.githubusercontent.com/crabsatellite/crabsatellite/main/assets/github-snake.svg"
                        alt="Github Snake"
                        style={{
                          width: "100%", // 让图片适应父容器宽度
                          maxWidth: maxWidth, // 使用动态 maxWidth
                          height: "auto", // 保持图片的纵横比
                          margin: "0 auto", // 使图片在水平上居中
                          display: "block", // 将图片设置为块级元素，以应用 margin: 0 auto
                        }}
                        onError={handleImageError}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 animate-box">
              <p>
                <a
                  href="https://github.com/crabsatellite"
                  className="btn btn-primary btn-lg btn-github-snake"
                >
                  GO TO MY GITHUB
                  <i className="icon-github" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GithubSnake;
