import { message } from "antd";
import React, { Component } from "react";

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section
          id="colorlib-hero"
          className="js-fullheight"
          data-section="home"
        >
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li style={{ backgroundImage: "url(images/img_bg1.jpg)" }}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1 style={{ color: "SeaShell", FontFace: "Roboto" }}>
                            Let's Power The Society Forward.
                          </h1>
                          <p>
                            <a
                              className="btn btn-primary btn-learn"
                              href="mailto:ALFullStackEngineer@gmail.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                border: "1px solid SeaShell",
                                boxShadow: "0px 0px 3px SeaShell",
                                color: "SeaShell",
                              }}
                            >
                              Contact Me <i className="icon-mail" />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li style={{ backgroundImage: "url(images/img_bg0.jpg)" }}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1 style={{ color: "whitesmoke" }}>
                            Innovating Finance,
                            <br />
                            Empowering Futures.
                          </h1>
                          <p>
                            <a
                              className="btn btn-primary btn-learn"
                              href="#research-notes"
                              data-nav-section="research-notes"
                              rel="noopener noreferrer"
                              onClick={this.handleNavClick}
                              style={{
                                border: "1px solid whitesmoke",
                                boxShadow: "0px 0px 3px whitesmoke",
                                color: "whitesmoke",
                              }}
                            >
                              Research Notes <i className="icon-note" />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li style={{ backgroundImage: "url(images/img_bg2.jpg)" }}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1 style={{ color: "whitesmoke" }}>
                            Advancing Technology,
                            <br />
                            Shaping Tomorrow.
                          </h1>
                          <p>
                            <a
                              className="btn btn-primary btn-learn"
                              href="#projects"
                              data-nav-section="projects"
                              rel="noopener noreferrer"
                              onClick={this.handleNavClick}
                              style={{
                                border: "1px solid whitesmoke",
                                boxShadow: "0px 0px 3px whitesmoke",
                                color: "whitesmoke",
                              }}
                            >
                              View Projects <i className="icon-briefcase3" />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }

  handleNavClick = (e) => {
    e.preventDefault();
    const target = e.currentTarget.getAttribute("data-nav-section");
    if (target === "research-notes") {
      message.info("Coming Soon!");
      return;
    }
    const targetElement = document.querySelector(`[data-section="${target}"]`);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };
}
