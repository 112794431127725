import React, { useState } from "react";
import { Modal, Button, Input, Checkbox, message } from "antd";
import "../../css/home/sidebar.css";
import axios from "axios";
const apiGateWay = process.env.REACT_APP_API_GATEWAY;
const githubUrl = process.env.REACT_APP_GITHUB_URL;
const colorlibUrl = process.env.REACT_APP_COLORLIB_HOME;
const antdUrl = process.env.REACT_APP_ANTD_HOME;

const SocialLink = ({ href, target, rel, children }) => (
  <li>
    <a href={href} target={target} rel={rel}>
      {children}
    </a>
  </li>
);

const NavItem = ({ href, section, children }) => (
  <li>
    <a href={href} data-nav-section={section}>
      {children}
    </a>
  </li>
);

const Sidebar = () => {
  const authorImgStyle = { backgroundImage: "url(images/about.jpg)" };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [leaveContact, setLeaveContact] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (comment === null || comment === "") {
      message.error("Message can not be empty.");
      return;
    }
    console.log("Comment submitted:", comment);

    // Check if leaveContact is true, if so, add name and contactInfo
    let payload = {
      message: comment,
    };

    if (leaveContact) {
      payload.name = contactName;
      payload.contactInfo = contactInfo;
    }

    // // Send a POST request
    try {
      const response = await axios.post(apiGateWay + "/message", payload);
      console.log("Response:", response.data);
      message.success("Message sent successfully!");
    } catch (error) {
      console.error("There was an error sending the message:", error);
      message.error("Sorry! This feature is currently unavailable.");
      message.error(error.message);
    }

    setIsModalVisible(false);
  };

  const handleContactNameChange = (e) => {
    setContactName(e.target.value);
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleLeaveContactChange = (e) => {
    setLeaveContact(e.target.checked);
  };

  return (
    <div>
      <div>
        <nav
          href="#navbar"
          className="js-colorlib-nav-toggle colorlib-nav-toggle"
          data-toggle="collapse"
          data-target="#navbar"
          aria-expanded="false"
          aria-controls="navbar"
        >
          <i />
        </nav>
        <aside id="colorlib-aside" className="border js-fullheight">
          <div className="text-center">
            <div className="author-img" style={authorImgStyle} />
            <h1 id="colorlib-logo">
              <a href={githubUrl} target="_blank" rel="noopener noreferrer">
                Alex Li
              </a>
            </h1>
            <span className="email">
              <i className="icon-mail"></i> ALFullStackEngineer@gmail.com
            </span>
          </div>
          <nav id="colorlib-main-menu" role="navigation" className="navbar">
            <div id="navbar" className="collapse">
              <ul>
                <NavItem href="#home" section="home">
                  Introduction
                </NavItem>
                <NavItem href="#about" section="about">
                  About
                </NavItem>
                <NavItem href="#github_snake" section="github_snake">
                  Code Commitments
                </NavItem>
                <NavItem href="#projects" section="projects">
                  Projects
                </NavItem>
                <NavItem href="#timeline" section="timeline">
                  Timeline
                </NavItem>
              </ul>
            </div>
          </nav>
          <nav id="colorlib-main-menu">
            <ul>
              <SocialLink
                href={githubUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-github" />
              </SocialLink>
              <SocialLink
                href="/minecraft"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icon-home" />
              </SocialLink>
            </ul>
          </nav>
          <div className="colorlib-footer">
            <p>
              <small>
                I <i className="icon-heart" aria-hidden="true" /> Programming!
                <br />
                Special Thanks to:{" "}
                <a href={antdUrl} target="_blank" rel="noopener noreferrer">
                  Ant Design
                </a>
                {" and "}
                <a href={colorlibUrl} target="_blank" rel="noopener noreferrer">
                  Colorlib
                </a>
              </small>
            </p>
            <nav id="colorlib-main-menu">
              <ul>
                <li>
                  <Button className="myButton" onClick={handleOpenModal}>
                    Send a message
                  </Button>
                </li>
              </ul>
            </nav>

            <Modal
              title="Add a message"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Input.TextArea
                rows={6}
                value={comment}
                onChange={handleChangeComment}
                placeholder="Drop your thoughts here!"
              />
              <Checkbox
                style={{ marginTop: "16px" }}
                onChange={handleLeaveContactChange}
              >
                Leave your contact?
              </Checkbox>
              {leaveContact && (
                <div style={{ marginTop: "16px" }}>
                  <label htmlFor="contactName">Contact Information:</label>
                  <Input
                    id="contactName"
                    style={{ marginTop: "10px" }}
                    placeholder="Enter your name."
                    value={contactName}
                    onChange={handleContactNameChange}
                  />
                  <Input
                    id="contactInput"
                    style={{ marginTop: "16px" }}
                    placeholder="Enter your contact info."
                    value={contactInfo}
                    onChange={handleContactInfoChange}
                  />
                </div>
              )}
            </Modal>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
