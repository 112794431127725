import React, { Component } from "react";
import Typewriter from "typewriter-effect";
import "../../App.css";
export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInLeft"
                >
                  <div className="col-md-12">
                    <div className="about-desc, animate-box">
                      <span className="heading-meta">About This Page</span>
                      <h2 className="colorlib-heading">CrabSatellite Story</h2>
                      <div className="intro_article">
                        <Typewriter
                          options={{
                            delay: 5, // The delay between each character is 5ms
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(
                                "CrabSatellite is my personal website where I share my groundbreaking projects and deep industry insights with the world. <br/>"
                              )
                              .typeString(
                                "Much like a crab navigating the vast universe, with the internet as my vessel, I connect, innovate, and showcase my expertise and research."
                              )
                              .typeString(
                                "<img src='images/icon/icon-crab.png' style='width: 20px; height: 20px; margin-left: 10px; margin-right: 10px;'/><br/><br/>"
                              )
                              .typeString("\n\n")
                              .typeString(
                                "Fueled by a relentless passion for technology and finance, I continually expand my expertise through hands-on projects and in-depth industry analysis. <br/>My mission is to become a dynamic force in both tech and finance, creating cutting-edge solutions that drive progress.<br/><br/>"
                              )
                              .pauseFor(500)
                              .typeString("LET US SHOCK THE WORLD<br/><br/>")
                              .pauseFor(1000)
                              .typeString("-- Alex Lee")
                              .start();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
