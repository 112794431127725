import React, { Component } from "react";
import "../../css/home/recent_projects.css";
import { projectData } from "../../data/projectData";
const recentProjectList = [
  projectData.dicom_image_3d_builder,
  projectData.hotbath,
  projectData.crab_melodies,
  projectData.nft_browser,
  projectData.crab_stay,
  projectData.crab_eats,
];

export default class Projects extends Component {
  renderProjects() {
    return recentProjectList.map((project, index) => (
      <div
        key={index}
        className={`col-md-4 animate-box`}
        data-animate-effect="fadeInLeft"
      >
        <a href={project.href} target="_blank" rel="noopener noreferrer">
          <div
            className="project"
            style={{
              backgroundImage: `url(${project.imgSrc})`,
            }}
          >
            <div className="desc">
              <div className="con">
                <h3>{project.title}</h3>
                <span>{project.outline}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    ));
  }

  render() {
    return (
      <div>
        <section className="colorlib-work" data-section="projects">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">My Work</span>
                <h2 className="colorlib-heading animate-box">
                  Projects Pick Up
                </h2>
              </div>
            </div>
            <div className="row">{this.renderProjects()}</div>
            <div className="row">
              <div className="col-md-12 animate-box">
                <p>
                  <a
                    href="/projects"
                    className="btn btn-primary btn-lg btn-load-more"
                  >
                    See more <i className="icon-reload" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
