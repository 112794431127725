import React, { Component } from "react";
import "../../css/home/timeline.css";

class TimelineEntry extends Component {
  render() {
    const { animateEffect, color, iconSrc, title, span, tagType } = this.props;

    return (
      <article
        className={`timeline-entry animate-box ${animateEffect}`}
        data-animate-effect={animateEffect}
      >
        <div className="timeline-entry-inner">
          <div className={`timeline-icon ${color}`}>
            <div
              className="icon"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={iconSrc}
                style={{ width: "100%", height: "100%" }}
                alt="icon"
              />
            </div>
          </div>
          <div className="timeline-label">
            <h2>
              {title} <span>{span}</span>
            </h2>
            <div
              className={`timeline-tag ${tagType
                .toLowerCase()
                .replace(/ & /g, "-")
                .replace(/ /g, "-")}`}
            >
              {tagType}
            </div>
          </div>
        </div>
      </article>
    );
  }
}
export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">highlights</span>
                <h2 className="colorlib-heading animate-box">Timeline</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <TimelineEntry
                    animateEffect="fadeInTop"
                    color="color-white"
                    iconSrc="images/icon/icon-orient-securities.png"
                    title="Investment Banking Intern, Orient Securities"
                    span="2024.07–Present"
                    tagType="Finance"
                  />
                  <TimelineEntry
                    animateEffect="fadeInTop"
                    color="color-white"
                    iconSrc="images/icon/icon-guotai.png"
                    title="Securities Intern, Guotai Junan Securities"
                    span="2024.06–2024.07"
                    tagType="Finance"
                  />
                  <TimelineEntry
                    animateEffect="fadeInTop"
                    color="color-white"
                    iconSrc="images/icon/icon-NYU.png"
                    title="Masters Candidate of Computer Engineering, New York University"
                    span="2023.01-present"
                    tagType="Education"
                  />
                  <TimelineEntry
                    animateEffect="fadeInTop"
                    color="color-white"
                    iconSrc="images/icon/icon-Stanley.png"
                    title="Marketing Data Analyst Intern, Stanley Electric Co., Ltd"
                    span="2019.07-2019.10"
                    tagType="Other"
                  />
                  <TimelineEntry
                    animateEffect="fadeInTop"
                    color="color-white"
                    iconSrc="images/icon/icon-TUS.png"
                    title="Bachelor of Electrical Engineering, Tokyo University of Science"
                    span="2018.04-2022.03"
                    tagType="Education"
                  />
                  <div style={{ height: "50px" }}></div>
                  <article
                    className="timeline-entry begin animate-box"
                    data-animate-effect="fadeInBottom"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none"></div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
