import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Background from "./BackGround.js";
import "../css/ProjectPage.css";
import { projectData } from "../data/projectData.js";

function Projects() {
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    updateSearchFromURL();
  }, [location.search]);

  const handleSearch = (event) => {
    const search = event.target.value;
    window.location.href = `/projects?tag=${search}`;
  };

  const updateSearchFromURL = () => {
    const urlParams = new URLSearchParams(location.search);
    const search = urlParams.get("tag") || "";
    setSearch(search);
  };

  const filterProjects = () => {
    const projects = Object.values(projectData);
    if (!search) return projects.filter((project) => project.isVisible);

    return projects.filter(
      (project) =>
        project.isVisible &&
        project.tags.some((tag) => tag.toLowerCase() === search.toLowerCase())
    );
  };

  const renderProjects = () => {
    const filteredProjects = filterProjects();
    return filteredProjects.map((project, index) => (
      <div
        key={index}
        className={`col-md-4 animate-box`}
        data-animate-effect="fadeInLeft"
        style={{ animationDelay: `${index * 100}ms` }}
      >
        <a href={project.href} rel="noopener noreferrer">
          <div
            className="project"
            style={{
              backgroundImage: `url(${project.imgSrc})`,
            }}
          >
            <div className="desc">
              <div className="con">
                <h3>{project.title}</h3>
                <span>{project.category}</span>
                <span>Status: {project.status}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    ));
  };

  const allTags = Array.from(
    new Set(Object.values(projectData).flatMap((project) => project.tags))
  );
  return (
    <div>
      <Background
        videoSource="https://personalwebpage-videos.s3.us-east-2.amazonaws.com/background.mp4"
        posterSource={`${process.env.PUBLIC_URL}/images/background_poster.jpg`}
      />

      <section className="colorlib-work" data-section="projects">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div
              className="col-md-12 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <div className="flex-container">
                <div className="left-arrow-container">
                  <div className="left-arrow">
                    <a
                      href="/home"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <i className="icon-arrow-left3" />

                      <span className="left-arrow-text"> Back</span>
                    </a>
                  </div>
                </div>

                <div className="tag-selector-container">
                  <div className="ant-dropdown">
                    <select
                      className="ant-dropdown-selector"
                      value={search}
                      onChange={handleSearch}
                    >
                      <option value="">ALL</option>
                      {allTags.map((tag, index) => (
                        <option key={index} value={tag}>
                          {tag}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">{renderProjects()}</div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
