import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import { Card, Modal, Button, Tag, Image } from "antd";
import ImageCarousel from "./project_detail_components/ImageCarousel";
import Live2DMascotLoader from "./live2d_mascot_loader";
import {
  LeftOutlined,
  ApiOutlined,
  GithubOutlined,
  DownloadOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import Background from "./BackGround";
import "../css/ProjectDetail.css";

const ProjectDetail = ({ project }) => {
  const [readme, setReadme] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  useEffect(() => {
    const githubLinks = [
      project.githubLink,
      project.githubFrontendLink,
      project.githubBackendLink,
    ].filter((link) => link);

    for (let link of githubLinks) {
      // Extract the repo owner and name from the link
      const linkParts = link.split("/");
      const owner = linkParts[linkParts.length - 2];
      const repo = linkParts[linkParts.length - 1];

      axios
        .get(`https://api.github.com/repos/${owner}/${repo}/readme`, {
          headers: { Accept: "application/vnd.github.3.raw" },
        })
        .then((response) => {
          setReadme((currentReadme) => currentReadme + response.data);
        })
        .catch((error) => {
          console.log("Error fetching README: ", error);
        });
    }
  }, [
    project.githubLink,
    project.githubFrontendLink,
    project.githubBackendLink,
  ]);

  const shortReadme = expanded
    ? readme
    : readme.slice(0, 200) + (readme.length > 200 ? "..." : "");

  return (
    <div>
      <div>
        <Background
          videoSource={`https://personalwebpage-videos.s3.us-east-2.amazonaws.com/${project.background_theme}_background.mp4`}
          posterSource={`${process.env.PUBLIC_URL}/images/background_posters/${project.background_theme}_background_poster.jpg`}
        />
      </div>
      <div className="project-detail-left-arrow">
        <Button
          href="/projects"
          className="project-detail-left-arrow-button"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <LeftOutlined className="icon-shadow" />
        </Button>
      </div>
      <div className="project-detail-content">
        <div className="project-detail-description fade-in-up">
          <Card title="Project Description" bordered={false}>
            <div dangerouslySetInnerHTML={{ __html: project.description }} />
          </Card>
        </div>
        <div className="project-detail-my-role fade-in-up">
          <Card title="My Role" bordered={false}>
            <p>{project.myRole}</p>
          </Card>
        </div>
        <div className="project-detail-tech-stack fade-in-up">
          <Card title="Tech Stack" bordered={false}>
            {project.techStack.map((tech, index) => (
              <Tag key={index} color="blue" className="custom-tag">
                {tech}
              </Tag>
            ))}
          </Card>
        </div>
        <div className="project-detail-readme fade-in-up">
          {project.githubLink && (
            <Card title="README from github" bordered={false}>
              <ReactMarkdown>
                {shortReadme ||
                  "Fail to fetch README, the project may currently be private..."}
              </ReactMarkdown>
              {readme.length > 200 && (
                <Button onClick={() => setExpanded(!expanded)}>
                  {expanded ? "Show Less" : "Show More"}
                </Button>
              )}
            </Card>
          )}
        </div>
        <div className="project-detail-in-progress fade-in-up">
          {project.endDate === "N/A (In Progress)" && (
            <Card
              title="Oops! Nothing more. The project is still in progress!"
              bordered={false}
            >
              <Image
                className="project-detail-in-progress-image fade-in-up"
                src={`${process.env.PUBLIC_URL}/images/in_progress.png`}
                preview={false}
              />
            </Card>
          )}
          {project.endDate === "N/A (Fixing Bugs)" && (
            <Card
              title="Oops! It seems like the project is currently under maintenance!"
              bordered={false}
            >
              <Image
                className="project-detail-in-progress-image fade-in-up"
                src={`${process.env.PUBLIC_URL}/images/in_progress.png`}
                preview={false}
              />
            </Card>
          )}
        </div>
      </div>

      {project.display_img_list && project.display_img_list.length > 0 && (
        <div className="project-detail-carousel fade-in-up">
          <ImageCarousel imgList={project.display_img_list} />
        </div>
      )}

      <div className="project-detail-button-container fade-in-up">
        {project.demoLink && (
          <Button
            type="primary"
            href={project.demoLink}
            target="_blank"
            className="project-detail-demo-button fade-in-up"
            icon={<ApiOutlined />}
          >
            Demo
          </Button>
        )}
        {project.demoVideoLink && (
          <Button
            type="primary"
            onClick={() => setShowVideoModal(true)}
            className="project-detail-demo-video-button fade-in-up"
            icon={<PlayCircleOutlined />}
          >
            Demo Video
          </Button>
        )}
        <Modal
          visible={showVideoModal}
          onCancel={() => setShowVideoModal(false)}
          footer={null}
          width={700}
          destroyOnClose // this prop unmounts the child components when modal is closed
        >
          <ReactPlayer
            url={project.demoVideoLink}
            playing
            controls
            width="100%"
          />
        </Modal>

        {project.downloadLink && (
          <Button
            type="primary"
            href={project.downloadLink}
            target="_blank"
            className="project-detail-download-button fade-in-up"
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        )}
        {project.githubLink && (
          <Button
            type="primary"
            href={project.githubLink}
            target="_blank"
            className="project-detail-github-button fade-in-up"
            icon={<GithubOutlined />}
          >
            Github
          </Button>
        )}
        {project.githubFrontendLink && (
          <Button
            type="primary"
            href={project.githubFrontendLink}
            target="_blank"
            className="project-detail-github-button fade-in-up"
            icon={<GithubOutlined />}
          >
            Github (Frontend)
          </Button>
        )}
        {project.githubBackendLink && (
          <Button
            type="primary"
            href={project.githubBackendLink}
            target="_blank"
            className="project-detail-github-button fade-in-up"
            icon={<GithubOutlined />}
          >
            Github (Backend)
          </Button>
        )}
      </div>
      <Live2DMascotLoader />
    </div>
  );
};

export default ProjectDetail;
