import React, { Component } from "react";
import Sidebar from "./sidebar";
import Introduction from "./introduction";
import About from "./about";
import Timeline from "./timeline";
import Projects from "./recent_projects";
import GithubSnake from "./github_snake";
import Live2DMascotLoader from "../live2d_mascot_loader";

class Home extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
          <Sidebar></Sidebar>
          <div id="colorlib-main">
            <Introduction></Introduction>
            <About></About>
            <GithubSnake></GithubSnake>
            <Projects></Projects>
            <Timeline></Timeline>
            <Live2DMascotLoader />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
