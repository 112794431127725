import React, { useState, useEffect } from "react";
import { Carousel, Modal } from "antd";

function ImageCarousel({ imgList }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePreview = (imgSrc) => {
    setPreviewImage(imgSrc);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Carousel autoplay className="project-detail-carousel">
        {imgList.map((img, index) => (
          <div
            key={index}
            onClick={() => handlePreview(img)}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={img}
              alt={`Slide ${index}`}
              style={{
                cursor: "pointer",
                objectFit: "cover",
                maxHeight: "100%",
                width: isLandscape ? "100%" : "auto",
              }}
            />
          </div>
        ))}
      </Carousel>

      <Modal
        visible={isModalVisible}
        onCancel={handleClose}
        footer={null}
        centered
        width="80%" // adjust width as needed
        style={{ top: 20 }} // adjust as needed
      >
        <img
          alt="Preview"
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
}

export default ImageCarousel;
